import React from 'react';

import { Text } from 'components';
import { drawBorder } from 'utils';

import ErrorIcon from '../../assets/errorIcon.svg';

function GeneralError() {
    const { container, image, text1, text2, text3, text4 } = style;

    const paymentId = '';
    const orderId = '';

    return (
        <div style={container}>
            <img style={image} src={ErrorIcon} width={115} height={85} alt="icon" />

            <Text type="Header" ownStyle={text1}>
                Что-то пошло не так
            </Text>
            <Text ownStyle={text2}> Пожалуйста, повторите попытку. </Text>

            {orderId && (
                <Text ownStyle={text3}>
                    Номер заказа <b>123</b>
                </Text>
            )}

            {paymentId && (
                <Text ownStyle={text4}>
                    Номер платежа <b>1234564</b>
                </Text>
            )}
        </div>
    );
}

const style = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    image: {
        marginTop: '125px',
    },
    text1: {
        marginTop: '20px',
    },
    text2: {
        marginTop: '10px',
    },
    text3: {
        marginTop: '50px',
    },
    text4: {
        marginTop: '5px',
    },
};

export default GeneralError;
