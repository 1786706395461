import React from 'react';

import { Button, Text } from 'components';

import PrinterIcon from '../assets/print.svg';

function InfoSuccess(props) {
    const { info, infoText, thankYouText, cellNameStyle, printerContainer } = styles;

    const { data, sign, onButtonClick, backUrl, success } = props;
    const { merchant, payment } = data;

    const { name } = merchant;
    const { amount, commission, card } = payment;

    function CellText(cellProps) {
        const { cellName, cellData } = cellProps;

        return (
            <tr>
                <td style={cellNameStyle}>{cellName}</td>
                <td style={infoText}>{cellData}</td>
            </tr>
        );
    }

    function autoRediect() {
        if (!success) {
            return;
        }

        setTimeout(() => {
            window.location.href = success;
        }, 5000);
    }

    autoRediect();

    return (
        <div style={info}>
            <Text type="Header">Платеж успешно завершен!</Text>
            <Text ownStyle={thankYouText}>Спасибо за покупку</Text>
            <table style={{ paddingTop: '40px' }}>
                <tbody>
                    <CellText cellName="Продавец" cellData={name} />
                    <CellText cellName="Номер заказа" cellData={payment.id} />
                    <CellText cellName="Комиссия" cellData={commission + sign} />
                    <CellText cellName="Итого" cellData={amount + sign} />
                    {
                        card && card.firstSix && card.lastFour ? <CellText cellName="Карта" cellData={`${card.firstSix}xxxxxx${card.lastFour}`}/> : null
                    }
                </tbody>
            </table>
            <div style={printerContainer}>
                <img src={PrinterIcon} alt="printer" />
                <div style={{ marginLeft: '8px', color:'#1C2546' }}>
                    <a style={{ color:'#1C2546' }} href={`${window.location}/receipt`} target="_blank" rel="noreferrer">Напечатать чек</a>
                </div>
            </div>
            {backUrl && <Button text="Вернутся в магазин" onClick={onButtonClick} />}
        </div>
    );
}

const styles = {
    info: {
        display: 'flex',
        flexDirection: 'column',
        flex: 3,
        alignItems: 'flex-start',
    },
    infoText: {
        marginLeft: '30px',
        fontWeight: '700',
        paddingLeft: '50px',
        fontSize: '14px',
    },
    printerContainer: {
        marginTop: '15px',
        marginBottom: '15px',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    thankYouText: {
        paddingTop: '10px',
    },
    cellNameStyle: {
        fontWeight: '400',
        fontSize: '14px',
    },
};

export default InfoSuccess;
