import React from 'react';
import { getCurrencyData } from 'utils';
import { Header, Content, Footer } from './components';

function StatusPage(props) {
    const { main, wrapper } = styles;
    const { data } = props;
    const { sign } = getCurrencyData();

    return (
        <div style={main}>
            <div style={wrapper}>
                <Header sign={sign} amount={data.payment.amount}/>
                <Content data={data} sign={sign} />
                <Footer />
            </div>
        </div>
    );
}

const styles = {
    main: {
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
    },
    wrapper: {
        paddingTop: '65px',
        display: 'flex',
        flexDirection: 'column',
        width: '400px',
    },
};

export default StatusPage;
