import React from 'react';
import { Document, Image, Page, View, Text, Link } from '@react-pdf/renderer';

import Logo from '../../assets/Ov_logo.png';

const Br = () => <View style={{ backgroundColor: '#E3E3E3', height: '2px', width: '100%', marginTop: '20px' }} />;

const BoldText = ({ children, style }) => <Text style={{ fontWeight: 700, ...style }}>{children}</Text>;

const LineText = (props) => {
    const { left, right } = props;

    if (right === '') {
        return (<View style={{ height: '1px', width: '100%' }} />)
    }

    return (
        <View style={pdfStyles.textRow}>
            <Text
                style={{
                    width: '50%',
                }}
            >
                {left}
            </Text>
            <BoldText
                style={{
                    width: '50%',
                    textAlign: 'right',
                }}
            >
                {right}
            </BoldText>
        </View>
    );
};

const PDFReceipt = ({ data }) => {
    const {
        paymentId,
        commission,
        total,
        merchantName,
        paymentTime,
        paymentDetails,
        paymentDescription,
        currency,
        bik,
        orderId,
    } = data;

    const { page, header, image, textRow, textThankyou } = pdfStyles;

    return (
        <Document>
            <Page size={{ width: 480, height: 715 }}>
                <View style={page}>
                    <View style={header}>
                        <Image src={Logo} style={image} />
                        <Text>Вы успешно оплатили</Text>
                        <Text>
                            платеж №{paymentId} в пользу {merchantName}
                        </Text>
                    </View>
                    <Br />

                    <View style={textRow}>
                        <BoldText
                            style={{
                                width: '50%',
                            }}
                        >
                            {`Платеж №${paymentId}`}
                        </BoldText>
                        <BoldText
                            style={{
                                width: '50%',
                                textAlign: 'right',
                            }}
                        >
                            {paymentTime}
                        </BoldText>
                    </View>
                    <LineText left="Заказ" right={orderId} />
                    <LineText left="Платежная организация" right='ТОО "OneVision"' />
                    <LineText left="Поставщик услуг" right={merchantName} />
                    <LineText left="БИК" right={bik} />
                    <LineText left="Детали платежа" right={paymentDetails} />
                    <LineText left="Назначение платежа" right={paymentDescription} />
                    <LineText left="Валюта операции" right={currency} />

                    <Br />

                    <View style={{ ...textRow }}>
                        <BoldText
                            style={{
                                color: '#4CC3F7',
                            }}
                        >
                            ИТОГ
                        </BoldText>
                        <BoldText
                            style={{
                                color: '#4CC3F7',
                            }}
                        >
                            {total}₸
                        </BoldText>
                    </View>

                    <View style={textRow}>
                        <Text>Комиссия</Text>
                        <Text>{commission}₸</Text>
                    </View>

                    <View style={textThankyou}>
                        <Text>Спасибо за покупку!</Text>
                    </View>

                    <View style={{ marginTop: '35px', alignItems: 'center' }}>
                        <Text>Если у Вас возникли дополнительные вопросы, наша</Text>
                        <Text>служба поддержки обязательно ответит на ваши вопросы</Text>
                        <View style={{ flexDirection: 'row' }}>
                            <Text>по адресу </Text>
                            <Link
                                href="mailto:support@onevision.kz"
                                style={{ fontWeight: 700, textDecoration: 'none', color: '#4CC3F7' }}
                            >
                                support@onevision.kz
                            </Link>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

const pdfStyles = {
    page: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        alignItems: 'center',
        fontFamily: 'Verdana',
        marginLeft: '35px',
        marginRight: '35px',
        color: '#4F4F4F',
        fontSize: 13,
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    image: {
        width: '121px',
        height: '50px',
        marginTop: '43px',
        marginBottom: '32px',
    },
    text: {
        fontFamily: 'Verdana',
    },
    textRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '13px',
        fontFamily: 'Verdana',
        fontSize: 13,
    },
    textHeader: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    textThankyou: {
        marginTop: '45px',
        justifyContent: 'center',
        fontSize: 24,
        fontWeight: 700,
        color: '#4CC3F7',
    },
};

export default PDFReceipt;
