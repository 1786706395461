/* eslint-disable */
import priceFormat from './format';
import ovColors from './colors';

const drawBorder = (color = 'rgba(0, 0, 0, 0.05)', width = 1) => ({
    border: `${width}px solid ${color}`,
});

const getCurrencyData = (currency = '') => {
    const data = [
        {
            currency: 'kzt',
            sign: '₸',
            name: 'Казахстанский тенге',
        },
        {
            currency: 'rub',
            sign: '₽',
            name: 'Рубль',
        },
    ];

    const signData = data.find((i) => i.currency.toLocaleLowerCase() === currency.toLocaleLowerCase());

    return signData ? signData : data[0];
};

const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

const retryRequest = async (request, times = 20, delay = 5000) => {
    if (times <= 0) {
        throw new Error('Timeout');
    }
    try {
        const result = await request;
        if (result.data.code === 1000) {
            return result;
        } else {
            await timeout(delay);
            return retryRequest(request, times - 1);
        }
    } catch (error) {
        throw error;
    }
};

export { drawBorder, ovColors, priceFormat, getCurrencyData, retryRequest };
