/**
 * Format price
 *
 * @param  {Number} amount
 * @param  {Object} params
 * @return {String}
 */
const priceFormat = (amount, params) => {
  const sp = "\u2009";
  const param = params || {
    exponent: 2,
    thousandsSeparator: "&nbsp;",
    decimalMark: ".",
  };
  const sep = param.thousandsSeparator === " " ? sp : param.thousandsSeparator;
  const value = Number(amount)
    .toFixed(param.exponent)
    .replace(".", param.decimalMark);

  return value.replace(/\B(?=(\d{3})+(?!\d))/g, sep).toString();
};

export default priceFormat;
