import React from 'react';

import { priceFormat } from 'utils';

const Button = (props) => {
    const { text, amount, sign, isError, style = {}, onClick = () => {} } = props;

    return (
        <button
            type="button"
            style={{
                ...styles.btn,
                background: isError ? '#E45F5F' : '#33BE67',
                ...style,
            }}
            onClick={onClick}
        >
            {text}

            {amount && (
                <span>
                    {` ${priceFormat(amount)}`}
                    <span>{sign}</span>
                </span>
            )}
        </button>
    );
};

const styles = {
    btn: {
        border: 'none',
        textAlign: 'center',
        width: '230px',
        height: '45px',
        color: '#ffffff',
        display: 'block',
        marginBottom: '15px',
        cursor: 'pointer',
        fontSize: '14px',
        fontWeight: '300',
        borderRadius: '5px',
        transition: 'background-color 0.5s ease',
        boxShadow: '0px 73px 80px rgba(13, 16, 27, 0.07)',
        textDecoration: 'none',
    },
};

export default Button;
