import React, { useState, useEffect } from 'react';

import { Preloader } from 'components';
import { paymentApi } from 'api';
import { StatusPage, GeneralError } from 'pages';
import { retryRequest } from 'utils';

const parseLocation = () => {
    const { pathname } = window.location;
    const pathArray = pathname.split('/');
    const token = pathArray[1];
    const key = pathArray[2];

    return { token, key };
};

const getPaymentInfo = async (token, key) => {
    try {
        const res = await paymentApi.getPaymentInfo(token, key);
        return res;
    } catch (error) {
        throw error;
    }
};

const getStatusInfo = async (token, key) => {
    try {
        return paymentApi.getPaymentStatus(token, key);
    } catch (error) {
        throw error;
    }
};

function App(props) {
    const [data, setData] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        const showStatusPage = (newData = { success: false }) => {
            setData(newData);
            setIsSuccess(newData.success);
            setIsLoaded(true);
        };

        const fetchData = async () => {
            try {
                const { key, token } = parseLocation(props);
                const responsePaymentInfo = await retryRequest(getPaymentInfo(key, token));
                const {
                    data: { payment, merchant, order },
                } = responsePaymentInfo;

                if (payment.status === 'new' || payment.status === 'process' || payment.status === 'waiting') {
                    const responseStatusInfo = await retryRequest(getStatusInfo(key, token));

                    // question: mercchant from init? card in payment when init?
                    showStatusPage({
                        merchant,
                        payment: responseStatusInfo.data.payment,
                        order: responseStatusInfo.data.order,
                        success: true,
                    });
                } else {
                    showStatusPage({ merchant, payment, order, success: true });
                }
            } catch (error) {
                showStatusPage();
            }
        };

        fetchData();
    }, []);

    if (!isLoaded) {
        return <Preloader />;
    }

    return isSuccess ? <StatusPage data={data} /> : <GeneralError />;
}

export default App;
