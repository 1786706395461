import axios from 'axios';

const url = window.BASE_URL || 'https://pay.onevision.kz/v1';

const getPaymentInfo = (token = '', key = '') => {
    if (token === '') {
        throw new Error('Token is empty');
    }

    if (key === '') {
        throw new Error('Key is empty');
    }

    return axios.post(`${url}/${key}/${token}/init`, {
        timeout: 1000 * 20,
        headers: {
            'Content-Type': 'application/json',
        }
    });
};

const getPaymentStatus = (token, key) => {
    if (token === '') {
        throw new Error('Token is empty');
    }

    if (key === '') {
        throw new Error('Key is empty');
    }

    return axios.post(`${url}/${key}/${token}/status`, {
        timeout: 1000 * 20,
        headers: {
            'Content-Type': 'application/json',
        }
    });
};

const paymentApi = {
    getPaymentInfo,
    getPaymentStatus,
};

export default paymentApi;
