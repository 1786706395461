import React from 'react';
import { ovColors } from 'utils';
import { Text } from 'components';

function Footer() {
    const { footer, text, offer } = styles;
    return (
        <div style={footer}>
            <hr />
            <Text ownStyle={text}>Безопасная оплата кредитной картой. Защищено сертификатом SSL.</Text>
            <Text ownStyle={text}>
                Нажимая на кнопку, вы принимаете условия{' '}
                <a style={offer} href="https://onevision.kz/rules.pdf" target="_blank" rel="noreferrer">
                    Оферты
                </a>
            </Text>
        </div>
    );
}

const styles = {
    footer: {
        flex: '1',
    },
    text: {
        color: ovColors.ov_gray,
        textAlign: 'center',
        lineHeight: '15px',
        fontSize: '12px',
    },
    offer: {
        color: ovColors.ov_black,
        backgroundColor: 'transparent',
    },
};

export default Footer;
