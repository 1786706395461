import React from 'react';
import logo from '../../assets/logo.svg';

function Preloader() {
    const { main, text } = styles;

    return (
        <div style={main}>
            <img src={logo} alt="preloader" />
            <span style={text}>Платёж находится в обработке...</span>
        </div>
    );
}

const styles = {
    main: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
    text: {
        paddingTop: '50px',
        color: '#495057',
    },
};

export default Preloader;
