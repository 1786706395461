import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { PDFViewer, NotFound } from 'pages';

import App from './App';

import './index.css';

Sentry.init({
    dsn: "https://4a1c9c67db934af4a57a15aa7aef07e8@o1061761.ingest.sentry.io/6068152",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
});

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Switch>
                <Route path="/:token/:key" exact>
                    <App />
                </Route>
                <Route path="/:token/:key/receipt">
                    <div style={{ height: '100vh' }}>
                        <PDFViewer />
                    </div>
                </Route>
                <Route path="*">
                    <NotFound />
                </Route>
            </Switch>
        </Router>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
