import React from 'react';
import { Button } from 'components';

function InfoFailrue(props) {
    const { info, infoText } = styles;

    const { data, failure, onButtonClick, backUrl } = props;
    const { code, message } = data;

    function autoRediect() {
        if (!failure) {
            return;
        }

        setTimeout(() => {
            window.location.href = failure;
        }, 5000);
    }

    autoRediect();

    return (
        <div style={info}>
            <div
                style={{
                    fontWeight: '700',
                    fontSize: '20px',
                }}
            >
                Ошибка оплаты
            </div>
            <div
                style={{
                    fontWeight: '400',
                    fontSize: '15px',
                }}
            >
                Пожалуйста, повторите попытку.
            </div>

            {code && (
                <table style={{ paddingTop: '30px' }}>
                    <tbody>
                        <tr>
                            <td>Номер заказа</td>
                            <td style={infoText}>123</td>
                        </tr>

                        <tr>
                            <td>Номер платежа</td>
                            <td style={infoText}>123</td>
                        </tr>

                        <tr>
                            <td style={{ paddingTop: '30px' }}>Код ошибки </td>
                            <td style={{ ...infoText, ...{ paddingTop: '30px', color: 'red' } }}>{code}</td>
                        </tr>

                        {message && (
                            <tr>
                                <td>{message}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}

            {backUrl && <Button text="Вернутся в магазин" isError onClick={onButtonClick} />}
        </div>
    );
}

const styles = {
    info: {
        display: 'flex',
        flexDirection: 'column',
        flex: 3,
        alignItems: 'flex-start',
    },
    infoText: {
        marginLeft: '30px',
        fontWeight: '700',
        paddingLeft: '50px',
    },
};

export default InfoFailrue;
