import React from 'react';

import { ovColors } from 'utils';
import { Text } from 'components';

function Header(props) {
    const { container, text, sum } = styles;
    const { sign, amount } = props;

    return (
        <div>
            <div style={container}>
                <Text ownStyle={text}>СУММА</Text>
                <div style={sum}>
                    {
                        amount && sign && (
                            <span>{amount + sign}</span>
                        )
                    }
                </div>
            </div>

            <hr />
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    text: {
        color: ovColors.ov_gray,
        fontWeight: '400',
    },
    sum: {
        color: ovColors.ov_black,
        fontWeight: '700',
    },
};

export default Header;
