import React, { useState, useEffect } from 'react';
import { PDFViewer, Font } from '@react-pdf/renderer';

import { paymentApi } from 'api';
import { getCurrencyData, priceFormat, retryRequest } from 'utils';
import { GeneralError } from 'pages';
import { Preloader } from 'components';

import PDFReceipt from './PDFReceipt';
import { Verdana, VerdanaBold } from '../../assets';

const registerFont = () => {
    Font.register({
        family: 'Verdana',
        fonts: [
            { src: Verdana, fontWeight: 400 },
            { src: VerdanaBold, fontWeight: 700 },
        ],
    });

    // Переписываем функцию для переноса строк
    Font.registerHyphenationCallback((word) => word.split("-"));
};

const parseLocation = () => {
    const { pathname } = window.location;
    const pathArray = pathname.split('/');
    const token = pathArray[1];
    const key = pathArray[2];

    return { token, key };
};

const getPaymentInfo = async (token, key) => {
    try {
        const res = await paymentApi.getPaymentInfo(token, key);
        return res;
    } catch (error) {
        throw error;
    }
};

const getStatusInfo = async (token, key) => {
    try {
        return paymentApi.getPaymentStatus(token, key);
    } catch (error) {
        throw error;
    }
};

function PdfViewer(props) {
    const [data, setData] = useState({
        paymentId: '',
        merchantName: '',
        paymentTime: '',
        paymentDetails: '',
        payment: '',
        currency: 'tenge',
        total: '',
        commission: '',
    });
    const [isLoaded, setIsLoaded] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { key, token } = parseLocation(props);

                const responsePaymentInfo = await retryRequest(getPaymentInfo(key, token));
                const responseStatusInfo = await retryRequest(getStatusInfo(key, token));

                const {
                    data: { payment, merchant, order },
                } = responsePaymentInfo;

                const { card, commission, times, total, currency, bank } = responseStatusInfo.data.payment;
                const currencyData = getCurrencyData(currency);

                setData({
                    paymentId: payment.id,
                    orderId: order.reference,
                    merchantName: merchant.name,
                    paymentTime: times.paid,
                    paymentDetails: card.firstSix && card.lastFour ? `${card.firstSix}xx xxxx ${card.lastFour}` : '',
                    paymentDescription: order.description,
                    currency: currencyData.name,
                    total: `${priceFormat(total, {
                        exponent: 2,
                        thousandsSeparator: "  ",
                        decimalMark: ".",
                    })}`,
                    commission: `${priceFormat(commission,{
                        exponent: 2,
                        thousandsSeparator: "  ",
                        decimalMark: ".",
                    })}`,
                    bik: `${bank.bik}`,
                });
                setIsLoaded(true);
            } catch (error) {
                setIsError(true);
            }
        };

        registerFont();
        fetchData();
    }, []);

    if (isError) {
        return <GeneralError />;
    }

    if (!isLoaded) {
        return <Preloader />;
    }

    return (
        isLoaded && (
            <PDFViewer width="100%" height="100%">
                <PDFReceipt data={data} />
            </PDFViewer>
        )
    );
}

export default PdfViewer;
