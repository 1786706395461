import React from 'react';
import { Text } from 'components';

function NotFound(props) {
    const { main, text, text2, text3, container } = styles;
    return (
        <div style={main}>
            <div style={container}>
                <Text type="Header" ownStyle={text}>
                    404
                </Text>
                <Text type="Header" ownStyle={text2}>
                    Страница не найдена
                </Text>
                <Text ownStyle={text3}>Запрошенный ресурс не может быть найден на этом сервере!</Text>
            </div>
        </div>
    );
}

const styles = {
    main: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    text: {
        fontSize: '150px',
    },
    text2: {
        fontSize: '25px',
    },
    text3: {
        paddingTop: '15px',
        fontSize: '15px',
    },
};

export default NotFound;
