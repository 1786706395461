import React from 'react';
import colors from 'utils/colors';

const collorMapper = [
    {
        textColor: 'black',
        color: colors.ov_black,
    },
    {
        textColor: 'red',
        color: colors.ov_red,
    },
    {
        textColor: 'green',
        color: colors.ov_gray,
    },
    {
        textColor: 'gray',
        color: colors.ov_gray,
    },
];

function Text(props) {
    const { children, type = '', textColor = '', ownStyle = {} } = props;

    const textStyle = styles[type.toLowerCase()] ? styles[type.toLowerCase()] : styles.defaultStyle;
    const currentColor = collorMapper.find((c) => c.textColor === textColor);

    return (
        <div style={{ ...textStyle, color: currentColor ? currentColor.color : collorMapper[0].color, ...ownStyle }}>
            {children}
        </div>
    );
}

const styles = {
    header: {
        fontWeight: '700',
        fontSize: '18px',
    },
    defaultStyle: {
        fontWeight: '400',
        fontSize: '14px',
    },
    black: {
        color: colors.ov_black,
    },
};

export default Text;
