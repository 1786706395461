import React from 'react';

import SuccessIcon from '../assets/successIcon.svg';
import ErrorIcon from '../../../assets/errorIcon.svg';
import logosIcon from '../assets/logos.svg';

import InfoSuccess from './InfoSuccess';
import InfoFailrue from './InfoFailure';

function Content(props) {
    const { container, content, logos } = styles;
    const { icon } = content;

    const { data, sign, onButtonClick } = props;
    const isSuccess = data.success;

    const {
        payment: { links },
    } = data;
    const { success, failure, back } = links;

    const redirectUrl = () => {
        const merchantUrl = isSuccess ? success : failure;
        const target = merchantUrl || back;

        window.location.href = target;
    };

    return (
        <div style={container}>
            <div style={content}>
                <div style={icon}>
                    <img src={isSuccess ? SuccessIcon : ErrorIcon} width={55} height={55} alt="icon" />
                </div>

                {isSuccess ? (
                    <InfoSuccess
                        data={data}
                        sign={sign}
                        success={success}
                        backUrl={links.back}
                        onButtonClick={redirectUrl}
                    />
                ) : (
                    <InfoFailrue
                        data={data}
                        sing={sign}
                        failure={failure}
                        backUrl={links.back}
                        onButtonClick={redirectUrl}
                    />
                )}
            </div>

            <div style={logos}>
                <img src={logosIcon} width="100%" alt="icons" />
            </div>
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '25px',
    },
    content: {
        display: 'flex',
        paddingTop: '35px',
        icon: {
            display: 'flex',
            flex: 1,
        },
    },
    logos: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '50px',
    },
};

export default Content;
